import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';

import s from './ResourceSet.scss';

export default class ResourceSet extends Component {

  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        cta: PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
          url: PropTypes.string,
        }).isRequired,
      }),
    ),
  };

  static defaultProps = {
    list: [],
  };

  render() {
    const { list } = this.props;

    return (
      <div className={s.resourceSet}>
        {list.map(resource => (
          <div className={s.resourceSet__resource} key={resource.heading}>
            <h3 className={s.resourceSet__heading}>{resource.heading}</h3>
            <p className={s.resourceSet__text}>{resource.text}</p>
            <div className={s.resourceSet__cta}>
              <Button to={resource.cta.url} color="transparent" icon={resource.cta.icon}>
                {resource.cta.text}
              </Button>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
