import { graphql } from 'gatsby';

import Product from 'routes/product/Product';

export default Product;

export const query = graphql`
  query Product($slug: String!) {
    page: contentfulTemplateProduct(slug: { eq: $slug }) {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroTextAlignment
      heroVerticalTextAlignment
      heroImage {
        ...image
      }
      heroBackgroundImage {
        ...image
      }
      heroVideoBg {
        file {
          url
        }
      }
      heroTheme
      heroHeading: heroTitle
      heroText: heroBody
      heroCtaIcon: heroCtaIconId
      heroCtaText
      heroCtaUrl
      modules {
        ...moduleList
      }
      greyHeading: greyTitle
      greyText: greyBody {
        text: greyBody
      }
      leaderCentered
      greyModules {
        ...moduleList
      }
      additionalModules {
        ...moduleList
      }
      additionalGreyModules {
        ...moduleList
      }
      tabLabels
      tabTechSpecs {
        ...headingAndText
      }
      tabTechSpecsCallout {
        ...resource
      }
      tabFaqs {
        ...headingAndText
      }
      tabResources {
        ...resource
      }
      ctaList {
        ...moduleList
      }
    }
  }

  fragment resource on ContentfulResource {
    heading: title
    text: body {
      text: body
    }
    ctaIcon: ctaIconId
    ctaText
    ctaUrl
  }
`;
