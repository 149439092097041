import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Markdown from 'components/markdown';

import s from './TechSpecSet.scss';

export default class TechSpecSet extends Component {

  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
    children: PropTypes.node,
  };

  static defaultProps = {
    list: [],
    children: null,
  };

  render() {
    const { list, children } = this.props;

    return (
      <div className={s.techSpecSet}>
        <div className={s.techSpecSet__table}>
          {list.map(techSpec => (
            <div className={s.techSpecSet__techSpec} key={techSpec.heading}>
              <div className={s.techSpecSet__border}>
                <h3 className={s.techSpecSet__heading}>{techSpec.heading}</h3>
                <div className={s.techSpecSet__text}>
                  <Markdown source={techSpec.text} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {children}
      </div>
    );
  }
}
