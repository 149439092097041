import React, { PureComponent, Fragment } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';

import Modules from 'containers/modules';
import Hero from 'components/hero';
import GreyArea from 'components/grey-area';
import Segment from 'components/layout';
import Tabs, { TabContent } from 'components/tabs';
import TechSpecSet from 'components/tech-spec-set';
import Callout from 'components/callout';
import AccordionList from 'components/accordion-list';
import ResourceSet from 'components/resource-set';
import SEO from 'components/seo';


const hasLetters = text => text?.search(/[A-Za-z]+/) >= 0;
class Product extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    const { ui } = this.props;

    ui.setNavTheme(this.props.data.page.heroTheme);
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { ui } = this.props;
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    const isPresenceAwarePage = pathname.indexOf('presence-aware') > 0;
    const isMobileAccessManager = pathname.indexOf('mobile-access-manager') > 0;
    const isMobileAccessConnector = pathname.indexOf('mobile-access-connector') > 0;

    return (
      <Fragment>
        <SEO pathname={pathname} article {...extractMeta(page)} />
        <Hero
          image={{
            src: _get(page.heroImage, 'file.url'),
            alt: _get(page.heroImage, 'description'),
            width: _get(page.heroImage, 'file.details.image.width'),
            height: _get(page.heroImage, 'file.details.image.height'),
          }}
          centered={page.heroTextAlignment === 'center'}
          verticalTextAlign={page.heroVerticalTextAlignment}
          videoBg={_get(page, 'heroVideoBg.file.url')}
          poster={_get(page.heroBackgroundImage, 'file.url')}
          theme={ui.navTheme}
          heading={page.heroHeading}
          text={page.heroText}
          textWidth="22.3em"
          cta={(page.heroCtaUrl && hasLetters(page.heroCtaText)) ? ({
            icon: page.heroCtaIcon,
            text: page.heroCtaText || 'Learn more',
            url: page.heroCtaUrl,
          }) : null}
        />

        <LazyLoad once offset={500}>
          <Modules list={page.modules} />
        </LazyLoad>

        <GreyArea
          heading={page.greyHeading}
          text={_get(page.greyText, 'text')}
          leaderCentered={page.leaderCentered}
        >
          <Modules list={(!hasLetters(page.greyModules[0].heading) && (isMobileAccessConnector || isMobileAccessManager)) ? [page.greyModules[0], {}] : page.greyModules } />
        </GreyArea>

        {(page.additionalModules && hasLetters(page.additionalModules[0].heading)) &&
          <LazyLoad once offset={500}>
            <Modules list={page.additionalModules} />
          </LazyLoad>
        }

        <GreyArea>
          {(page.additionalGreyModules && hasLetters(page.additionalGreyModules.heading)) &&
            <LazyLoad once offset={500}>
              <Modules list={page.additionalGreyModules} />
            </LazyLoad>
          }

          {page.tabLabels &&
            <Segment>
              <Tabs theme="block" labels={page.tabLabels}>
                <TabContent>
                  <TechSpecSet
                    list={page.tabTechSpecs.map(spec => ({
                      heading: spec.heading,
                      text: _get(spec.text, 'text'),
                    }))}
                  >
                    {page.tabTechSpecsCallout &&
                      <Callout
                        heading={_get(page.tabTechSpecsCallout, 'heading')}
                        cta={{
                          icon: _get(page.tabTechSpecsCallout, 'ctaIcon'),
                          text: _get(page.tabTechSpecsCallout, 'ctaText'),
                          url: _get(page.tabTechSpecsCallout, 'ctaUrl'),
                          color: 'transparent',
                        }}
                      />
                    }
                  </TechSpecSet>
                </TabContent>
                <TabContent>
                  <AccordionList
                    list={page.tabFaqs.map(qAndA => ({
                      heading: qAndA.heading,
                      text: _get(qAndA.text, 'text'),
                    }))}
                  />
                </TabContent>
                <TabContent>
                  <ResourceSet
                    list={page.tabResources.map(resource => ({
                      heading: resource.heading,
                      text: _get(resource.text, 'text'),
                      cta: {
                        icon: resource.ctaIcon,
                        text: resource.ctaText,
                        url: resource.ctaUrl,
                      },
                    }))}
                  />
                </TabContent>
              </Tabs>
            </Segment>
          }
        </GreyArea>
        {page.ctaList &&
          <LazyLoad once offset={500}>
            <Modules list={ hasLetters(page.ctaList[0].ctaText) ? page.ctaList : isPresenceAwarePage ? [page.ctaList[0], {}] : []} />
          </LazyLoad>
        }
      </Fragment>
    );
  }
}

export default withUIContext(Product);
